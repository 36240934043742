import qs, { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import {
  AddFieldDeviceDeploymentRingRequest,
  CommandResponse,
  CustomRJSFSchema,
  DeploymentRing,
  DeploymentRingCreateRequest,
  DeploymentRingFieldDevice,
  DeploymentRingUpdateRequest,
  DeviceCommand,
  DeviceConfigurationDiff,
  FieldDevice,
  FieldDeviceAuthAction,
  FieldDeviceAuthActionRequest,
  FieldDeviceAuthActionResponse,
  FieldDeviceClaim,
  FieldDeviceClaimDto,
  FieldDeviceConfiguration,
  FieldDeviceDeploymentRingDetail,
  FieldDeviceDetails,
  FieldDeviceDetailsDto,
  FieldDeviceDto,
  FieldDeviceFilterArgs,
  FieldDeviceIdentifier,
  FieldDeviceIdentifierDto,
  FieldDeviceProperty,
  FieldDevicePropertyDto,
  FieldDeviceReportedConfig,
  FieldDeviceReportedConfigDto,
  Rollout,
  RolloutCreateRequest,
  RolloutPackage,
  RolloutPackageArgs,
  RolloutPackageCreateRequest,
  RolloutPackageDesiredConfig,
  RolloutPackageDesiredConfigArgs,
  RolloutPackageDesiredConfigCreateRequest,
  RolloutPackageDesiredConfigUpdateRequest,
  RolloutPackageUpdateRequest,
  RolloutUpdateRequest,
  SelectorArgs,
  SelectorCreateRequest,
  SelectorDto,
  SoftwarePackage,
  SoftwarePackageArgs,
  SoftwarePackageDefault,
  SoftwarePackageDefaultRequest,
  SoftwarePackageLog,
  SoftwarePackageLogArgs,
  SoftwarePackageLogDto,
  SoftwarePackageVersion,
  SoftwarePackageVersionArgs,
  SoftwarePackageVersionConfig,
  SoftwarePackageVersionConfigArgs,
  SoftwarePublisher
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { IFieldOpsGateway } from "@libs/api/gateways/field/field-ops-gateway.interface";
import { mapFieldDeviceIdentifierDtoToIdentifier } from "@libs/api/gateways/field/field-ops-gateway.utils";
import {
  mapRolloutFromDto,
  RolloutArgsDto,
  RolloutDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { RefDataDto, RefDataResponse } from "@libs/api/types/common-dtos";
import { guid } from "@libs/common/guid";
import { PagingResponse } from "@libs/paging/paging-response.type";
import { withPaging } from "@libs/paging/paging.utils";

import {
  MIN_PAGE_ITEMS,
  PAGING_PAGE_NUMBER,
  PAGING_TOTAL_COUNT,
  PAGING_TOTAL_PAGES
} from "../../types/headers.constants";

export class FieldOpsGateway implements IFieldOpsGateway {
  constructor(private api: AxiosInstance) {}

  async getDevices(
    filterArgs?: FieldDeviceFilterArgs,
    pageParam = 1
  ): Promise<PagingResponse<FieldDeviceDetails>> {
    const queryString = withPaging({
      ...filterArgs,
      page: pageParam,
      limit: MIN_PAGE_ITEMS
    });

    const response = await this.api.get(`/devices?${stringify(queryString)}`);

    const isMaxPages =
      +response.headers[PAGING_PAGE_NUMBER] >=
      +response.headers[PAGING_TOTAL_PAGES];

    const total = response.headers[PAGING_TOTAL_COUNT];

    const data: PagingResponse<FieldDeviceDetails> = {
      results:
        response.data !== "" && response.data !== undefined // Data is empty string when no results
          ? response?.data?.map(details => ({
              ...details,
              tokenExpiryUtc: details.tokenExpiryUtc
                ? DateTime.fromISO(details.tokenExpiryUtc)
                : null,
              controllerCreatedDateTimeUtc: details.controllerCreatedDateTimeUtc
                ? DateTime.fromISO(details.controllerCreatedDateTimeUtc)
                : null,
              controllerStartDateTimeUtc: details.controllerStartDateTimeUtc
                ? DateTime.fromISO(details.controllerStartDateTimeUtc)
                : null,
              controllerLastObservedDateTimeUtc: details.controllerLastObservedDateTimeUtc
                ? DateTime.fromISO(details.controllerLastObservedDateTimeUtc)
                : null,
              recoveryAgentCreatedDateTimeUtc: details.recoveryAgentCreatedDateTimeUtc
                ? DateTime.fromISO(details.recoveryAgentCreatedDateTimeUtc)
                : null,
              recoveryAgentStartDateTimeUtc: details.recoveryAgentStartDateTimeUtc
                ? DateTime.fromISO(details.recoveryAgentStartDateTimeUtc)
                : null,
              recoveryAgentLastObservedDateTimeUtc: details.recoveryAgentLastObservedDateTimeUtc
                ? DateTime.fromISO(details.recoveryAgentLastObservedDateTimeUtc)
                : null
            }))
          : null,
      next: isMaxPages ? undefined : pageParam + 1,
      total: total ? +total : undefined
    };

    return data;
  }

  async getDeviceDetails(fieldDeviceId: guid): Promise<FieldDeviceDetails> {
    const url = qs.stringifyUrl({
      url: `devices/${fieldDeviceId}/details`,
      query: {
        fieldDeviceId
      }
    });

    const { data } = await this.api.get<FieldDeviceDetailsDto>(url);

    return {
      ...data,
      tokenExpiryUtc: data.tokenExpiryUtc
        ? DateTime.fromISO(data.tokenExpiryUtc)
        : undefined,
      controllerCreatedDateTimeUtc: data.controllerCreatedDateTimeUtc
        ? DateTime.fromISO(data.controllerCreatedDateTimeUtc)
        : undefined,
      controllerStartDateTimeUtc: data.controllerStartDateTimeUtc
        ? DateTime.fromISO(data.controllerStartDateTimeUtc)
        : undefined,
      controllerLastObservedDateTimeUtc: data.controllerLastObservedDateTimeUtc
        ? DateTime.fromISO(data.controllerLastObservedDateTimeUtc)
        : undefined,
      recoveryAgentCreatedDateTimeUtc: data.recoveryAgentCreatedDateTimeUtc
        ? DateTime.fromISO(data.recoveryAgentCreatedDateTimeUtc)
        : undefined,
      recoveryAgentStartDateTimeUtc: data.recoveryAgentStartDateTimeUtc
        ? DateTime.fromISO(data.recoveryAgentStartDateTimeUtc)
        : undefined,
      recoveryAgentLastObservedDateTimeUtc: data.recoveryAgentLastObservedDateTimeUtc
        ? DateTime.fromISO(data.recoveryAgentLastObservedDateTimeUtc)
        : undefined
    };
  }

  async getDevice(fieldDeviceId: guid): Promise<FieldDevice> {
    const { data } = await this.api.get<FieldDeviceDto>(
      `devices/${fieldDeviceId}`
    );

    return {
      ...data,
      issueTimeUtc: data.issueTimeUtc
        ? DateTime.fromISO(data.issueTimeUtc)
        : undefined,
      notBeforeTimeUtc: data.notBeforeTimeUtc
        ? DateTime.fromISO(data.notBeforeTimeUtc)
        : undefined,
      tokenExpiryUtc: data.tokenExpiryUtc
        ? DateTime.fromISO(data.tokenExpiryUtc)
        : undefined,
      changeLog: {
        ...data.changeLog,
        createdDate: data.changeLog.createdDate
          ? DateTime.fromISO(data.changeLog.createdDate)
          : undefined,
        updatedDate: data.changeLog.updatedDate
          ? DateTime.fromISO(data.changeLog.updatedDate)
          : undefined
      }
    };
  }

  async getDeviceLogs(
    args?: SoftwarePackageLogArgs,
    pageParam = 1
  ): Promise<PagingResponse<SoftwarePackageLog>> {
    const queryString = withPaging({
      ...args,
      page: pageParam,
      limit: MIN_PAGE_ITEMS
    });

    const response = await this.api.get<SoftwarePackageLogDto[]>(
      `/deviceLog/GetByArgs?${stringify(queryString)}`
    );

    const isMaxPages =
      +response.headers[PAGING_PAGE_NUMBER] >=
      +response.headers[PAGING_TOTAL_PAGES];

    const total = response.headers[PAGING_TOTAL_COUNT];

    const data: PagingResponse<SoftwarePackageLog> = {
      results: response?.data?.map(details => ({
        ...details,
        blobLastModifiedDate: details.blobLastModifiedDate
          ? DateTime.fromISO(details.blobLastModifiedDate)
          : undefined,
        logFileDate: details.logFileDate
          ? DateTime.fromISO(details.logFileDate)
          : undefined,
        blobCreationDate: details.blobCreationDate
          ? DateTime.fromISO(details.blobCreationDate)
          : undefined
      })),
      next: isMaxPages ? undefined : pageParam + 1,
      total: total ? +total : undefined
    };

    return data;
  }

  async postDeploymentRing({
    code,
    description,
    displayName,
    isPublic
  }: DeploymentRingCreateRequest): Promise<DeploymentRing> {
    // Create a Deployment Ring
    const { data } = await this.api.post<DeploymentRing>("deploymentRings", {
      code,
      description,
      displayName,
      isPublic
    });
    return data;
  }

  async getDeploymentRings(): Promise<DeploymentRing[]> {
    // Get Deployment Rings
    const { data } = await this.api.get<DeploymentRing[]>("deploymentRings");

    return data;
  }

  async getDeploymentRing(deploymentRingId: string): Promise<DeploymentRing> {
    // Get Deployment Ring
    const { data } = await this.api.get<DeploymentRing>(
      `deploymentRings/${deploymentRingId}`
    );

    return data;
  }

  async deleteDeploymentRings(deploymentRingId: guid): Promise<void> {
    // Delete Deployment Rings
    const url = qs.stringifyUrl({
      url: `deploymentRings/${deploymentRingId}`
    });

    const { data } = await this.api.delete<void>(url);
    return data;
  }

  async updateDeploymentRings({
    code,
    description,
    displayName,
    isPublic,
    deploymentRingId,
    selectorId,
    eTag
  }: DeploymentRingUpdateRequest): Promise<DeploymentRing> {
    // Update Deployment Rings
    const url = qs.stringifyUrl({
      url: `deploymentRings/${deploymentRingId}`
    });

    const { data } = await this.api.put<DeploymentRing>(url, {
      code,
      description,
      displayName,
      isPublic,
      selectorId,
      eTag
    });
    return data;
  }

  async getRollouts(deploymentRingId?: string): Promise<Rollout[]> {
    const rolloutArgsDto: RolloutArgsDto = {
      deploymentRingId
    };

    const { data } = await this.api.get<RolloutDto[]>(
      `/rollouts?${stringify(rolloutArgsDto)}`
    );

    return !!data ? data.map(mapRolloutFromDto) : [];
  }

  async getRollout(rolloutId?: string): Promise<Rollout> {
    const { data } = await this.api.get<RolloutDto>(`/rollouts/${rolloutId}`);
    return mapRolloutFromDto(data);
  }

  async postRollout(request: RolloutCreateRequest): Promise<Rollout> {
    // Create a Rollout
    const { data } = await this.api.post<RolloutDto>("rollouts", request);
    return mapRolloutFromDto(data);
  }

  async updateRollout(request: RolloutUpdateRequest): Promise<Rollout> {
    // Update a Rollout
    const { data } = await this.api.put<RolloutDto>(
      `rollouts/${request.id}`,
      request
    );

    return mapRolloutFromDto(data);
  }

  async deleteRollout(rolloutId: guid): Promise<void> {
    // Delete Rollout
    const url = qs.stringifyUrl({
      url: `rollouts/${rolloutId}`
    });

    const { data } = await this.api.delete<void>(url);
    return data;
  }

  async getRolloutPackages(rolloutId: string): Promise<RolloutPackage[]> {
    const rolloutPackageArgs: RolloutPackageArgs = {
      rolloutId
    };

    const { data } = await this.api.get<RolloutPackage[]>(
      `/rolloutPackage?${stringify(rolloutPackageArgs)}`
    );

    return data;
  }

  async updateRolloutPackage(
    request: RolloutPackageUpdateRequest
  ): Promise<RolloutPackage> {
    // Update a Rollout Device Configuration
    const { data } = await this.api.put<RolloutPackage>(
      `rolloutPackage/${request.id}`,
      request
    );

    return data;
  }

  async postRolloutPackage(
    request: RolloutPackageCreateRequest
  ): Promise<RolloutPackage> {
    // Create a Rollout Device Configuration
    const { data } = await this.api.post<RolloutPackage>(
      "rolloutPackage",
      request
    );

    return data;
  }

  async deleteRolloutPackage(rolloutPackageId: string): Promise<void> {
    // Delete Rollout Device Configuration
    await this.api.delete<void>(`rolloutPackage/${rolloutPackageId}`);
  }

  async getRolloutPackageDesiredConfigs(
    rolloutPackageId: string
  ): Promise<RolloutPackageDesiredConfig[]> {
    const rolloutPackageDesiredConfigArgs: RolloutPackageDesiredConfigArgs = {
      rolloutPackageId
    };

    const { data } = await this.api.get<RolloutPackageDesiredConfig[]>(
      `/rolloutPackageDesiredConfig?${stringify(
        rolloutPackageDesiredConfigArgs
      )}`
    );

    return !!data ? data : [];
  }

  async createRolloutPackageDesiredConfig(
    request: RolloutPackageDesiredConfigCreateRequest
  ): Promise<RolloutPackageDesiredConfig> {
    // Create a Rollout Package Desired Config
    const { data } = await this.api.post<RolloutPackageDesiredConfig>(
      "rolloutPackageDesiredConfig",
      request
    );

    return data;
  }

  async updateRolloutPackageDesiredConfig(
    request: RolloutPackageDesiredConfigUpdateRequest
  ): Promise<RolloutPackageDesiredConfig> {
    // Update a Rollout Package Desired Config
    const { data } = await this.api.put<RolloutPackageDesiredConfig>(
      `rolloutPackageDesiredConfig/${request.id}`,
      request
    );

    return data;
  }

  async deleteRolloutPackageDesiredConfig(
    rolloutPackageDesiredConfigId: string
  ): Promise<void> {
    // Delete a Rollout Package Desired Config
    await this.api.delete(
      `rolloutPackageDesiredConfig/${rolloutPackageDesiredConfigId}`
    );
  }

  async getDeviceIdentifiers(
    fieldDeviceId: guid
  ): Promise<FieldDeviceIdentifier[]> {
    // Get Field Device Identifiers
    const url = qs.stringifyUrl({
      url: `identifiers/${fieldDeviceId}`
    });

    const { data } = await this.api.get<FieldDeviceIdentifierDto[]>(url);
    return data.map(mapFieldDeviceIdentifierDtoToIdentifier);
  }

  async getMostRecentDeviceIdentifierHistory(
    fieldDeviceId: guid
  ): Promise<FieldDeviceIdentifier[]> {
    // Get Field Device Identifiers
    const url = qs.stringifyUrl({
      url: `identifiers/${fieldDeviceId}/history/recent`
    });

    const { data } = await this.api.get<FieldDeviceIdentifierDto[]>(url);
    return data.map(mapFieldDeviceIdentifierDtoToIdentifier);
  }

  async getDeviceIdentifierHistory(
    fieldDeviceId: guid,
    identifierType: string
  ): Promise<FieldDeviceIdentifier[]> {
    // Get Field Device Identifier History
    const url = qs.stringifyUrl({
      url: `identifiers/${fieldDeviceId}/${identifierType}/history`
    });

    const { data } = await this.api.get<FieldDeviceIdentifierDto[]>(url);
    return data.map(mapFieldDeviceIdentifierDtoToIdentifier);
  }

  async getMostRecentDevicePropertyHistory(
    fieldDeviceId: guid
  ): Promise<FieldDeviceProperty[]> {
    // Get Field Device Properties
    const url = qs.stringifyUrl({
      url: `properties/${fieldDeviceId}/history/recent`
    });

    const { data } = await this.api.get<FieldDevicePropertyDto[]>(url);
    return data.map(property => {
      return {
        ...property,
        lastObservedUtc: property.lastObservedUtc
          ? DateTime.fromISO(property.lastObservedUtc)
          : undefined,
        prevObservedUtc: property.prevObservedUtc
          ? DateTime.fromISO(property.prevObservedUtc)
          : undefined,
        firstObservedUtc: property.firstObservedUtc
          ? DateTime.fromISO(property.firstObservedUtc)
          : undefined
      };
    });
  }

  async getDeviceClaims(fieldDeviceId: guid): Promise<FieldDeviceClaim[]> {
    // Get Field Device Claims
    const url = qs.stringifyUrl({
      url: `devices/fieldDevice/${fieldDeviceId}/claims`
    });

    const { data } = await this.api.get<FieldDeviceClaimDto[]>(url);
    return data?.map(claim => ({
      ...claim,
      changeLog: {
        ...claim.changeLog,
        createdDate: claim.changeLog?.createdDate
          ? DateTime.fromISO(claim.changeLog?.createdDate)
          : undefined,
        updatedDate: claim.changeLog?.updatedDate
          ? DateTime.fromISO(claim.changeLog?.updatedDate)
          : undefined
      }
    }));
  }

  async getFieldDeviceDesiredConfig(
    fieldDeviceId: string
  ): Promise<FieldDeviceConfiguration> {
    // Get Field Device Desired Config
    const url = qs.stringifyUrl({
      url: `devices/${fieldDeviceId}/desiredConfig`
    });

    const { data } = await this.api.get<FieldDeviceConfiguration>(url);
    return data;
  }

  async getSoftwarePackgeCommandJson(
    softwarePackageVersionId?: string
  ): Promise<CustomRJSFSchema[]> {
    // Get Software Package Command Json
    const url = qs.stringifyUrl({
      url: `softwarePackageVersions/${softwarePackageVersionId}/commands`
    });

    const { data } = await this.api.get<CustomRJSFSchema[]>(url);
    return data;
  }

  async getDeviceConfigurationDiff(
    fieldDeviceId: string
  ): Promise<DeviceConfigurationDiff> {
    // Get Device Configuration Diff
    const url = qs.stringifyUrl({
      url: `deviceConfigurationDiff/${fieldDeviceId}`
    });

    const { data } = await this.api.get<DeviceConfigurationDiff>(url);
    return data;
  }

  async getSoftwarePackages(
    args?: SoftwarePackageArgs
  ): Promise<SoftwarePackage[]> {
    // Get Software Packages
    const url = qs.stringifyUrl({
      url: "softwarePackage",
      query: { ...args }
    });

    const { data } = await this.api.get<SoftwarePackage[]>(url);
    return data;
  }

  async getSelectors(args?: SelectorArgs): Promise<SelectorDto[]> {
    // Get Selectors
    const url = qs.stringifyUrl({
      url: "selectors",
      query: { ...args }
    });

    const { data } = await this.api.get<SelectorDto[]>(url);
    return data;
  }

  async getSelector(selectorId: string): Promise<SelectorDto> {
    const { data } = await this.api.get<SelectorDto>(`selectors/${selectorId}`);
    return data;
  }

  async postSelector(request: SelectorCreateRequest): Promise<SelectorDto> {
    const { data } = await this.api.post<SelectorDto>("selectors", request);
    return data;
  }

  async putSelector(selector: SelectorDto): Promise<SelectorDto> {
    const { data } = await this.api.put<SelectorDto>(
      `selectors/${selector.id}`,
      selector
    );
    return data;
  }

  async updateSelectorDeviceCount(selector: SelectorDto): Promise<SelectorDto> {
    const { data } = await this.api.put<SelectorDto>(
      `selectors/${selector.id}/deviceCount`,
      selector
    );
    return data;
  }

  async getSoftwarePublishers(): Promise<SoftwarePublisher[]> {
    // Get Software Publishers
    const { data } = await this.api.get<SoftwarePublisher[]>(
      "/softwarePublishers"
    );

    return data;
  }

  async getSoftwarePackageVersions(
    args?: SoftwarePackageVersionArgs
  ): Promise<SoftwarePackageVersion[]> {
    // Get Software Package Versions
    const queryString = withPaging({
      ...args,
      page: 1,
      limit: 200
    });

    const { data } = await this.api.get<SoftwarePackageVersion[]>(
      `/softwarePackageVersions?${stringify(queryString)}`
    );

    return data;
  }

  async getSoftwarePackageVersionsForRollout(
    rolloutId: string
  ): Promise<SoftwarePackageVersion[]> {
    // Get Software Package Versions for Rollout
    const { data } = await this.api.get<SoftwarePackageVersion[]>(
      `/softwarePackageVersions/${rolloutId}`
    );

    return data;
  }

  async getSoftwarePackageVersionConfig(
    softwarePackageVersionId: string
  ): Promise<SoftwarePackageVersionConfig[]> {
    const softwarePackageVersionConfigArgs: SoftwarePackageVersionConfigArgs = {
      softwarePackageVersionId
    };

    const { data } = await this.api.get<SoftwarePackageVersionConfig[]>(
      `/softwarePackageVersionConfig?${stringify(
        softwarePackageVersionConfigArgs
      )}`
    );

    return !!data ? data : [];
  }

  async getSoftwarePackageDefaults(): Promise<SoftwarePackageDefault[]> {
    // Get Software Package Defaults
    const url = qs.stringifyUrl({
      url: "softwarePackageDefaults"
    });

    const { data } = await this.api.get<SoftwarePackageDefault[]>(url);
    return data;
  }

  async updateSoftwarePackageDefault(
    request: SoftwarePackageDefault
  ): Promise<SoftwarePackageDefault> {
    return (
      await this.api.put<SoftwarePackageDefault>(
        `softwarePackageDefaults/${request.id}`,
        request
      )
    ).data;
  }

  async postSoftwarePackageDefault(
    request: SoftwarePackageDefaultRequest
  ): Promise<SoftwarePackageDefault> {
    const { data } = await this.api.post<SoftwarePackageDefault>(
      "softwarePackageDefaults",
      request
    );

    return data;
  }

  async regenerateSoftwarePackageDefaultConfig(): Promise<void> {
    await this.api.post("softwarePackageDefaults/regenerate");
  }

  async purgeFieldmanCDN(): Promise<void> {
    await this.api.get("CDN/purge");
  }

  async clearDeviceFieldConfigurationCache(
    fieldDeviceId: string
  ): Promise<void> {
    await this.api.post(`devices/${fieldDeviceId}/clearConfigCache`);
  }

  async getFieldDeviceAuthActions(
    fieldDeviceId: string,
    showExecuted: boolean
  ): Promise<FieldDeviceAuthAction[] | undefined> {
    // Get Auth Actions
    const url = qs.stringifyUrl({
      url: "fieldDeviceAuthAction",
      query: {
        fieldDeviceId,
        isExecuted: showExecuted === false ? false : undefined
      }
    });

    const { data } = await this.api.get<FieldDeviceAuthActionResponse[]>(url);

    if (!data) {
      return undefined;
    }

    return data?.map(authAction => ({
      ...authAction,
      runDate: authAction.runDate
        ? DateTime.fromISO(authAction.runDate)
        : undefined,
      changeLog: {
        ...authAction.changeLog,
        createdDate: authAction.changeLog.createdDate
          ? DateTime.fromISO(authAction.changeLog.createdDate)
          : undefined,
        updatedDate: authAction.changeLog.updatedDate
          ? DateTime.fromISO(authAction.changeLog.updatedDate)
          : undefined
      }
    }));
  }

  async postFieldDeviceAuthActions(
    request: FieldDeviceAuthActionRequest
  ): Promise<FieldDeviceAuthAction> {
    // Post Auth Actions
    const url = qs.stringifyUrl({
      url: "fieldDeviceAuthAction"
    });

    const { data } = await this.api.post<FieldDeviceAuthActionResponse>(
      url,
      request
    );

    return {
      ...data,
      runDate: data.runDate ? DateTime.fromISO(data.runDate) : undefined,
      changeLog: {
        ...data.changeLog,
        createdDate: data.changeLog.createdDate
          ? DateTime.fromISO(data.changeLog.createdDate)
          : undefined,
        updatedDate: data.changeLog.updatedDate
          ? DateTime.fromISO(data.changeLog.updatedDate)
          : undefined
      }
    };
  }

  async updateFieldDeviceAuthActions(
    request: FieldDeviceAuthAction
  ): Promise<FieldDeviceAuthAction> {
    // Update Auth Action
    const { data } = await this.api.put<FieldDeviceAuthAction>(
      `fieldDeviceAuthAction/${request.id}`,
      request
    );
    return data;
  }

  async deleteFieldDeviceAuthActions(authActionId: string): Promise<void> {
    // Delete Auth Action
    await this.api.delete<void>(`fieldDeviceAuthAction/${authActionId}`);
  }

  async getAuthActionRefData(): Promise<RefDataDto[]> {
    // Get Auth Actions
    const url = qs.stringifyUrl({
      url: "ref/AuthActionTypes"
    });

    const { data } = await this.api.get<RefDataResponse[]>(url);

    return data?.map(refData => ({
      ...refData,
      changeLog: {
        ...refData.changeLog,
        createdDate: refData.changeLog.createdDate
          ? DateTime.fromISO(refData.changeLog.createdDate)
          : undefined,
        updatedDate: refData.changeLog.updatedDate
          ? DateTime.fromISO(refData.changeLog.updatedDate)
          : undefined
      }
    }));
  }

  async getSelectorDataTypeRefData(): Promise<RefDataDto[]> {
    // Get Auth Actions
    const url = qs.stringifyUrl({
      url: "ref/selectorRuleDataType"
    });

    const { data } = await this.api.get<RefDataResponse[]>(url);

    return data?.map(refData => ({
      ...refData,
      changeLog: {
        ...refData.changeLog,
        createdDate: refData.changeLog.createdDate
          ? DateTime.fromISO(refData.changeLog.createdDate)
          : undefined,
        updatedDate: refData.changeLog.updatedDate
          ? DateTime.fromISO(refData.changeLog.updatedDate)
          : undefined
      }
    }));
  }

  async getFieldDeviceDeploymentRingDetails(
    fieldDeviceId?: string,
    deploymentRingId?: string
  ): Promise<FieldDeviceDeploymentRingDetail[]> {
    // Get Field Device Deployment Ring Details
    const url = qs.stringifyUrl({
      url: "deploymentRingFieldDevice/details",
      query: {
        fieldDeviceId,
        deploymentRingId
      }
    });

    const { data } = await this.api.get<FieldDeviceDeploymentRingDetail[]>(url);
    return data;
  }

  async getFieldDeviceReportedConfig(
    fieldDeviceId: string
  ): Promise<FieldDeviceReportedConfig[]> {
    // Get Field Device reported config
    const url = qs.stringifyUrl({
      url: `devices/${fieldDeviceId}/reportedConfig`
    });

    const { data } = await this.api.get<FieldDeviceReportedConfigDto[]>(url);

    return data?.map(
      config =>
        ({
          ...config,
          createdDateTimeUtc: config.createdDateTimeUtc
            ? DateTime.fromISO(config.createdDateTimeUtc)
            : undefined,
          startDateTimeUtc: config.startDateTimeUtc
            ? DateTime.fromISO(config.startDateTimeUtc)
            : undefined,
          lastObservedDateTimeUtc: config.lastObservedDateTimeUtc
            ? DateTime.fromISO(config.lastObservedDateTimeUtc)
            : undefined
        } as FieldDeviceReportedConfig)
    );
  }

  async postFieldDeviceDeploymentRing(
    request: AddFieldDeviceDeploymentRingRequest
  ): Promise<DeploymentRingFieldDevice> {
    // Create a link between the Field Device and a Deployment Ring
    const { data } = await this.api.post<DeploymentRingFieldDevice>(
      "deploymentRingFieldDevice",
      request
    );
    return data;
  }

  async deleteFieldDeviceDeploymentRing(
    deploymentRingFieldDeviceId: string
  ): Promise<void> {
    // Delete Field Device Deployment Ring link
    await this.api.delete<void>(
      `deploymentRingFieldDevice/${deploymentRingFieldDeviceId}`
    );
  }

  async updateFieldDeviceDeploymentRing(
    request: DeploymentRingFieldDevice
  ): Promise<DeploymentRingFieldDevice> {
    // Update the Field Device Deployment Ring
    const url = qs.stringifyUrl({
      url: `deploymentRingFieldDevice/${request.id}`
    });

    const { data } = await this.api.put<DeploymentRingFieldDevice>(
      url,
      request
    );
    return data;
  }

  async dispatchDeviceCommand(
    request: DeviceCommand
  ): Promise<CommandResponse> {
    const { data } = await this.api.post<CommandResponse>(
      "fieldGateway/command/dispatch",
      request
    );

    return data;
  }
}
